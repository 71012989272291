/**
 * @see {@link https://developers.google.com/tag-platform/tag-manager/datalayer#custom_data_layer_methods | Custom data layer methods}
 */
type CustomDataLayerMethods = {
  set: (key: string, value: unknown) => void
  get: (key: string) => unknown
  reset: () => void
}

type DataLayer = Array<
  Record<string, unknown> | ((this: CustomDataLayerMethods) => void)
>

type WindowWithDataLayer = Window & {
  dataLayer: DataLayer
}

// This strategy is used to avoid poluting the global scope, and to force the use of the `gtm` object
declare const window: WindowWithDataLayer

/**
 * Load Google Tag Manager (GTM) script
 * Ideally, we should be adding the GTM script directly in the HTML file as high in the <head> of the page as possible.
 * However, since this is a CRA (react-scripts) project, we can't handle the conditional environment variables in the HTML.
 */
export const loadGTM = (gtmId: string) => {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  // we are sure that the document will have at least one script tag
  // remember that if this code was loaded it needed to be loaded through a script tag
  const firstScript = document.getElementsByTagName('script')[0]
  const newScript = document.createElement('script')
  newScript.async = true
  /**
   * If we want to, we can use a different name for the dataLayer variable.
   * This is useful when we want to use multiple GTM containers on the same page.
   * In this case, we need to set the `l` URL search parameter to the name we want to use and we need to update the index.html to safely initiate this attribute value.
   * @see {@link https://developers.google.com/tag-platform/tag-manager/datalayer#rename_the_data_layer | Rename the data layer}
   */
  newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`

  /**
   * Document and DocumentFragment nodes can never have a parent, so parentNode will always return null.
   * It also returns null if the node has just been created and is not yet attached to the tree
   * Since we queried this element from the three and it is an HTMLScriptElement we use the non-null assertion operator `!` to avoid the TS error
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/parentNode | parentNode}
   */
  firstScript.parentNode!.insertBefore(newScript, firstScript)
}
